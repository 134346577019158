import {createApi} from '@reduxjs/toolkit/query/react'

import {axiosBaseQuery} from '../../../utils/network/axiosBaseQuery'
import {LinkType} from '../../types/Link'
import {API_KEY_HEADER, REQUEST_ID_HEADER} from '../bookApi'

export interface GetPaymentOptionsRequest {
  link: LinkType
  headers: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
  }
  experimentApiKey?: string
  httpRequestId: string
}

export interface CreditCard {
  code: string
  mandatoryText: string | null
  name: string
  processorCountryCode: string | null
}

export interface PaymentOptions {
  creditCardTypes: CreditCard[]
}

export const paymentOptionsApi = createApi({
  reducerPath: 'paymentOptionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: ''
  }),
  serializeQueryArgs: ({endpointName}) => endpointName,
  endpoints: builder => ({
    getPaymentOptions: builder.query<PaymentOptions, GetPaymentOptionsRequest>({
      query: ({link, headers, experimentApiKey, httpRequestId}) => {
        const apiKeyHeader = experimentApiKey
          ? {[API_KEY_HEADER]: experimentApiKey}
          : {}

        return {
          url: link.href,
          headers: {
            ...headers,
            ...apiKeyHeader,
            [REQUEST_ID_HEADER]: httpRequestId
          },
          method: link.method
        }
      }
    })
  })
})

export const {useGetPaymentOptionsQuery} = paymentOptionsApi
