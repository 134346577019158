import React, {memo} from 'react'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useExecutionIfNotAuthenticated} from '@daedalus/core/src/auth/hooks/useExecutionIfNotAuthenticated'
import {Brand} from '@daedalus/core/src/brand/types'

import {AuthDialogLoadable} from './AuthDialog'
import Container, {ChildrenProps} from './Container'

type PasswordlessLoginPropsType = {
  brand: Brand
  languageCode: string
  anonymousId: string
  isPopupClosable?: boolean
}

export type ViewProps = ChildrenProps &
  PasswordlessLoginPropsType & {
    isMobile: boolean
  }

export const PasswordlessLogin = ({
  brand,
  languageCode,
  anonymousId,
  isPopupClosable = true
}: PasswordlessLoginPropsType) => {
  const {brandShowSignIn} = useBrand()

  useExecutionIfNotAuthenticated(
    async () =>
      import(
        /* webpackPrefetch: true */ './AuthDialog/AuthDialog' /* webpackChunkName: "AuthDialog" */
      )
  )

  if (!brandShowSignIn) {
    return null
  }

  return (
    <>
      <Container
        languageCode={languageCode}
        anonymousId={anonymousId}
        brand={brand}
      >
        {({
          isOpen,
          authenticationFlow,
          handleCloseOverlay,
          handleSocialLoginClick
        }) =>
          isOpen ? (
            <AuthDialogLoadable
              brand={brand}
              isOpen={isOpen}
              handleCloseOverlay={handleCloseOverlay}
              viewProps={{
                brand,
                handleSocialLoginClick
              }}
              authenticationFlow={authenticationFlow}
              isPopupClosable={isPopupClosable}
            />
          ) : (
            <></>
          )
        }
      </Container>
    </>
  )
}

export default memo<PasswordlessLoginPropsType>(PasswordlessLogin)
