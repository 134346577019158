import {__, assoc, dissoc, pipe, prop} from 'ramda'

const renamePropName =
  <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ObjectToChange extends Record<string, any>,
    From extends string,
    To extends string
  >(
    from: From,
    to: To
  ) =>
  (objectToChange: ObjectToChange) =>
    pipe(
      prop(from),
      assoc(to, __, objectToChange),
      dissoc(from)
    )(objectToChange)

export default renamePropName
