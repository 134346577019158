import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemePropType} from 'types/Theme'

import {Button} from '@daedalus/atlas/Button'
import {ButtonGroup} from '@daedalus/atlas/ButtonGroup'
import {Stack} from '@daedalus/atlas/helpers/Stack'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {FakeDatePickerInput} from './FakeDatePickerInput'
import {FakeDestinationInput} from './FakeDestinationInput'
import {FakeGuestPickerInput} from './FakeGuestPickerInput'

interface BaseProps {
  /** Check in date */
  checkIn: string
  /** Check out date */
  checkOut: string
  /** Rooms configuration */
  numberOfAdults: number
  /** Rooms configuration */
  numberOfChildren: number
  /** Rooms configuration */
  numberOfRooms: number
  /** Callback for Datepicker click */
  onDatePickerClick: (type: DatePickerType) => void
  /** Callback for Guestpicker click */
  onGuestPickerClick: () => void
  /** Callback for Submit click */
  onSubmit: () => void
  /** Whether the rooms count and label is shown */
  isRoomsLabelShown?: boolean
}

interface PropsWithDestination extends BaseProps {
  /** Destination name */
  destination: string
  /** Callback for Destinationpicker click */
  onDestinationPickerClick: () => void
  /** Destination error flag */
  destinationHasError?: boolean
  /** Destination error message */
  destinationErrorMessage?: string
}

interface PropsWithoutDestination extends BaseProps {
  showDestination?: never
  destination?: never
  onDestinationPickerClick?: never
  destinationHasError?: never
  destinationErrorMessage?: never
}

type Props = PropsWithDestination | PropsWithoutDestination

const inputErrorStyle = ({theme}: ThemePropType) => css`
  padding: ${theme.layout.spacing.s300}px 0 0 ${theme.layout.spacing.s500}px;
`

const InputError = styled(Text)(inputErrorStyle)

export const SearchFormVertical = React.memo(function SearchFormVertical({
  checkIn,
  checkOut,
  destination,
  numberOfAdults,
  numberOfChildren,
  numberOfRooms,
  onDestinationPickerClick,
  onDatePickerClick,
  onGuestPickerClick,
  onSubmit,
  destinationHasError = false,
  destinationErrorMessage = '',
  isRoomsLabelShown = true
}: Props) {
  const handleDatePickerClick = (type: DatePickerType) => {
    // Only open checkOut when there is a checkIn set
    const openType = checkIn ? type : DatePickerType.CheckIn
    onDatePickerClick(openType)
  }

  return (
    <Stack space="s300">
      {onDestinationPickerClick && (
        <>
          <FakeDestinationInput
            value={destination}
            hasError={destinationHasError}
            onClick={onDestinationPickerClick}
          />
          {destinationHasError && (
            <InputError colorPath="content.danger.c700" variant="bodyXS">
              <FormattedMessageWrapper
                id={destinationErrorMessage}
                defaultMessage=""
              />
            </InputError>
          )}
        </>
      )}

      <ButtonGroup>
        <FakeDatePickerInput
          value={checkIn}
          type={DatePickerType.CheckIn}
          onClick={() => handleDatePickerClick(DatePickerType.CheckIn)}
        />
        <FakeDatePickerInput
          value={checkOut}
          type={DatePickerType.CheckOut}
          onClick={() => handleDatePickerClick(DatePickerType.CheckOut)}
        />
      </ButtonGroup>

      <div>
        <FakeGuestPickerInput
          numberOfAdults={numberOfAdults}
          numberOfChildren={numberOfChildren}
          numberOfRooms={numberOfRooms}
          onClick={onGuestPickerClick}
          isRoomsLabelShown={isRoomsLabelShown}
        />
      </div>

      <div>
        <Button
          variant="primaryGradient"
          fullWidth
          type="submit"
          dataId="SearchButton"
          onClick={onSubmit}
          iconStart={<Icon name="Search" />}
        >
          <FormattedMessageWrapper id="search" defaultMessage="Search" />
        </Button>
      </div>
    </Stack>
  )
})
