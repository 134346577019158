import {createAction} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'

import {ErrorResponse} from '@daedalus/core/src/booking/types/ErrorResponse'

export type AxiosErrorResponse = AxiosResponse<ErrorResponse> &
  AxiosError<ErrorResponse> &
  ErrorResponse

export const bookOfferFinalizationError = createAction<AxiosErrorResponse>(
  'bookOffer/bookOfferFinalizationError'
)

export const bookOfferFinalizationFatalError = createAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any> | string
>('bookOffer/bookOfferFinalizationFatalError')
