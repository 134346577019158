import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Divider} from '@daedalus/atlas/Divider'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import getBookingContext from '@daedalus/core/src/analytics/contexts/BookingContext'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {
  Action,
  AnalyticsContext,
  BaseOfferContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  getIsRtlLanguage,
  selectUserId
} from '@daedalus/core/src/auth/modules/selectors'
import {getLinkByType} from '@daedalus/core/src/offer/business/getLinkByType'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'
import PriceTypes from '@daedalus/core/src/offer/types/PriceTypes'
import {
  getDisplayPrice,
  getNightlyPrice,
  getPriceByType
} from '@daedalus/core/src/price/business/price'
import {numberOfGuests} from '@daedalus/core/src/room/business/roomConfiguration'
import {getAnonymousId} from '@daedalus/core/src/utils/anonymousId'
import {getTimezone} from '@daedalus/core/src/utils/date'

import {
  getAnalyticsContext,
  getApplication,
  getBookingStatus,
  getComponent,
  getError,
  getFreezeSelectedContext,
  getIsFreezeConfirmationOverlayOpen,
  getOfferEligibility
} from '../../modules/selector'
import {closeFreezeConfirmationOverlay} from '../../modules/slice'
import {
  useCheckUserLimitQuery,
  useGetFrozenBookingsQuery,
  useRetrieveBookingQuery
} from '../../services/dealFreezeApi'
import {getDayDistance} from '../../utils/date'
import {findRoomByOfferId} from '../../utils/offer'
import {getBookingManagementUrl} from '../../utils/url'
import {getDealFreezePaymentUrl} from '../../utils/url/getDealFreezePaymentUrl'
import {ConfirmationContent} from './ConfirmationContent'
import {CtaButtons} from './CtaButtons'

const DESKTOP_OVERLAY_WIDTH = 480

const OverlayWrapper = styled.div`
  height: calc(100vh - 133px);
  overflow: auto;
`

const CtaWrapper = styled.div(
  ({theme}) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    background-color: ${theme.colors.background.neutral.c000};
    overflow: hidden;
  `
)

export const FreezeConfirmationOverlay: React.FC = () => {
  const isRtlLanguage = useSelector(getIsRtlLanguage)
  const {isMobile} = useDeviceLayout()
  const {response: offerCheckResponse} = useSelector(getOfferEligibility)
  const {errored} = useSelector(getError)
  const {links} = useSelector(getBookingStatus) || {}
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsFreezeConfirmationOverlayOpen)
  const [trackedBookingIds, setTrackedBookingIds] = useState<string[]>([])
  const {hotel, searchDetail, sapiMatchedOffer, rooms} = useSelector(
    getFreezeSelectedContext
  )
  const {
    page,
    bookingManagementUrl: myBookingUrl,
    checkoutUrl
  } = useSelector(getApplication)
  const component = useSelector(getComponent)
  const brand = useSelector(getBrand)
  const cio = customerIo(brand)
  const userId = useSelector(selectUserId)
  const {leadContext, offerContext} = useSelector(getAnalyticsContext)

  const retrieveBookingLink = getLinkByType(
    links,
    ActionLinkTypes.BOOKING_RETRIEVE
  )

  const shouldSkipRetrieve = !retrieveBookingLink || !isOpen
  const retrieveBookingResult = useRetrieveBookingQuery(
    {url: retrieveBookingLink},
    {skip: shouldSkipRetrieve}
  )

  /**
   * Retrieve and update store's frozen booking data to toggle between "Freeze price" and "Manage freeze" buttons, and
   * also checking if user is still eligible for Deal Freeze (they may have reached the limit)
   */
  useGetFrozenBookingsQuery(
    {},
    {skip: shouldSkipRetrieve, refetchOnMountOrArgChange: true}
  )
  useCheckUserLimitQuery(
    {},
    {skip: shouldSkipRetrieve, refetchOnMountOrArgChange: true}
  )

  const {hotelName, starRating, imageURIs, displayAddress} = hotel || {}
  const {checkIn, checkOut, roomsSplit} = searchDetail || {}

  useEffect(() => {
    const bookingData = retrieveBookingResult?.data
    const bookingId = bookingData?.id
    const anonymousId = getAnonymousId() as string

    if (
      retrieveBookingResult.isError ||
      !bookingId ||
      trackedBookingIds.includes(bookingId)
    ) {
      return
    }

    const bookingManagementUrl =
      myBookingUrl &&
      getBookingManagementUrl({
        hostUrl: myBookingUrl,
        bookingId,
        anonymousId
      })

    const paymentUrl =
      checkoutUrl &&
      getDealFreezePaymentUrl({
        hostUrl: checkoutUrl,
        bookingId
      })

    const numberOfNights = getDayDistance(checkIn, checkOut)
    const totalGuests = numberOfGuests(roomsSplit)
    const price =
      getDisplayPrice(bookingData.prices) ||
      getPriceByType(PriceTypes.CHARGEABLE_CURRENCY, bookingData.prices)

    const nightlyPrice = getNightlyPrice(
      price?.chargeable.total,
      numberOfNights
    )

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.DealFreezeConfirmationOverlay,
        action: Action.Displayed,
        ...(component && {component}),
        analyticsContext: {
          [AnalyticsContext.BookingContext]: getBookingContext(bookingData),
          [AnalyticsContext.LeadContext]: leadContext,
          [AnalyticsContext.OfferContext]: offerContext as BaseOfferContext,
          [AnalyticsContext.MarketingAutomationContext]: {
            // Please ensure accuracy when modifying this object. Values are used in reminder emails.
            hotelName: hotelName,
            hotelImageUrl: imageURIs?.[0],
            bookingManagementUrl,
            paymentUrl,
            hotelAddress: displayAddress,
            price,
            numberOfNights,
            totalGuests,
            starRating,
            ...(nightlyPrice && {nightlyPrice})
          }
        },
        page,
        team: Team.Select
      })
    )

    cio.trackEvent({
      name: `${Category.User}_${Entity.DealFreeze}_${Action.Succeeded}`,
      pageName: page,
      userId,
      anonymousId: anonymousId,
      timezone: getTimezone(),
      hotelName: hotelName,
      hotelImageUrl: imageURIs?.[0],
      bookingData: {
        ...bookingData,
        bookingManagementUrl,
        paymentUrl
      },
      [AnalyticsContext.OfferContext]: offerContext as BaseOfferContext,
      hotelAddress: displayAddress,
      price,
      numberOfNights,
      totalGuests,
      starRating
    })
    setTrackedBookingIds([...trackedBookingIds, bookingId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, retrieveBookingResult])

  const room = findRoomByOfferId(rooms || [], sapiMatchedOffer?.id)

  const expiresAt = offerCheckResponse?.expiresAt as string

  const hideOverlay = () => dispatch(closeFreezeConfirmationOverlay())
  if (isMobile)
    return (
      <Overlay isOpen={isOpen} animationType={ANIMATION_TYPES.SLIDE_DOWN}>
        <ConfirmationContent
          expiresAt={expiresAt}
          hotelName={hotelName as string}
          checkIn={checkIn}
          checkOut={checkOut}
          roomsSplit={roomsSplit}
          room={room}
          offer={sapiMatchedOffer}
          imageUrl={imageURIs?.[0] as string}
          errored={errored}
          rating={starRating}
          onHide={hideOverlay}
        />
      </Overlay>
    )

  return (
    <Overlay
      animatePresenceInitial
      enableBodyScrollLock
      transitionDuration={300}
      isOpen={isOpen}
      onShadeBackgroundClick={hideOverlay}
      containerStyles={{
        width: DESKTOP_OVERLAY_WIDTH,
        marginLeft: `calc(100vw - ${DESKTOP_OVERLAY_WIDTH}px)`
      }}
      animationType={
        isRtlLanguage ? ANIMATION_TYPES.SLIDE_LEFT : ANIMATION_TYPES.SLIDE_RIGHT
      }
    >
      <OverlayWrapper>
        <ConfirmationContent
          isOverlayContent
          expiresAt={expiresAt}
          hotelName={hotelName as string}
          checkIn={checkIn}
          checkOut={checkOut}
          roomsSplit={roomsSplit}
          room={room}
          offer={sapiMatchedOffer}
          imageUrl={imageURIs?.[0] as string}
          errored={errored}
          rating={starRating}
          onHide={hideOverlay}
        />
      </OverlayWrapper>
      <CtaWrapper>
        <Divider />
        <ContentWrapper paddingY="s400" paddingX="s700">
          <CtaButtons isOverlayContent />
        </ContentWrapper>
      </CtaWrapper>
    </Overlay>
  )
}
