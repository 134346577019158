// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPushNotification = ({
  color = 'currentColor',
  size = 20
}: IconProps) => (
  <svg
    viewBox="0 0 61 57"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <g clipPath="url(#pushNotification_svg__a)">
      <mask
        id="pushNotification_svg__b"
        style={{
          maskType: 'luminance'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={0}
        width={56}
        height={57}
      >
        <path
          d="M58 28.5c0-15.464-12.536-28-28-28S2 13.036 2 28.5s12.536 28 28 28 28-12.536 28-28Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#pushNotification_svg__b)">
        <path
          d="M58 28.5c0-15.464-12.536-28-28-28S2 13.036 2 28.5s12.536 28 28 28 28-12.536 28-28Z"
          fill="#EAE4FF"
        />
        <path
          d="M41.57 11.875H18.302a3.177 3.177 0 0 0-3.177 3.177v45.271a3.177 3.177 0 0 0 3.177 3.177H41.57a3.177 3.177 0 0 0 3.177-3.177V15.052a3.177 3.177 0 0 0-3.177-3.177Z"
          fill="#0D0D0E"
        />
        <path
          d="M40.5 14.5h-21a1.75 1.75 0 0 0-1.75 1.75v42.875c0 .967.784 1.75 1.75 1.75h21a1.75 1.75 0 0 0 1.75-1.75V16.25a1.75 1.75 0 0 0-1.75-1.75Z"
          fill="#77C8F8"
        />
      </g>
      <path
        d="M49 27.5H12a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h37a2 2 0 0 0 2-2v-10a2 2 0 0 0-2-2Z"
        fill="#fff"
      />
      <g opacity={0.5} filter="url(#pushNotification_svg__c)">
        <path
          d="M47.829 25.5h-35.21A2.62 2.62 0 0 0 10 28.12v6.428a2.62 2.62 0 0 0 2.62 2.62h35.209a2.62 2.62 0 0 0 2.619-2.62V28.12a2.62 2.62 0 0 0-2.62-2.619Z"
          fill="#1B013A"
        />
      </g>
      <path
        d="M52.38 21.474H7.616a2.62 2.62 0 0 0-2.619 2.619V34.41a2.62 2.62 0 0 0 2.62 2.62H52.38A2.62 2.62 0 0 0 55 34.41V24.093a2.62 2.62 0 0 0-2.62-2.62Z"
        fill="#fff"
      />
      <path
        d="M15.81 23.696H9.74a2.521 2.521 0 0 0-2.521 2.52v6.07a2.521 2.521 0 0 0 2.52 2.522h6.07a2.521 2.521 0 0 0 2.522-2.521v-6.07a2.521 2.521 0 0 0-2.521-2.521Z"
        fill="#170033"
      />
      <path
        d="M15.912 25.919c.376 0 .612.41.423.738l-1.134 1.977a.723.723 0 0 1-.627.364h-1.747a.428.428 0 0 1-.368-.643l1.19-2.072a.723.723 0 0 1 .626-.364h1.636Z"
        fill="#F36"
      />
      <path
        d="M12.37 25.919c.377 0 .612.41.424.738l-1.557 2.713a.488.488 0 0 1-.848 0l-1.557-2.713a.492.492 0 0 1 .424-.738h3.114Z"
        fill="#FFC400"
      />
      <path
        d="M14.003 30.99a1.43 1.43 0 0 1-1.426 1.435 1.43 1.43 0 0 1-1.425-1.434 1.43 1.43 0 0 1 1.425-1.434 1.43 1.43 0 0 1 1.426 1.434Z"
        fill="#3C69F5"
      />
      <path
        d="M49.443 25.919h-27.78a1.111 1.111 0 1 0 0 2.222h27.78a1.111 1.111 0 1 0 0-2.222Z"
        fill="#000"
        fillOpacity={0.2}
      />
      <path
        d="M37.22 30.363H21.663a1.111 1.111 0 1 0 0 2.223H37.22a1.111 1.111 0 1 0 0-2.223Z"
        fill="#000"
        fillOpacity={0.1}
      />
    </g>
    <defs>
      <clipPath id="pushNotification_svg__a">
        <path fill="#fff" d="M0 0h61v57H0z" />
      </clipPath>
      <filter
        id="pushNotification_svg__c"
        x={0.178}
        y={15.678}
        width={60.092}
        height={31.312}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={4.911}
          result="effect1_foregroundBlur_1_10"
        />
      </filter>
    </defs>
  </svg>
)

export default SvgPushNotification
