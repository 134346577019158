import {createSelector} from '@reduxjs/toolkit'
import {__, includes, propOr} from 'ramda'

import {State} from '../../auth/modules/selectors'
import {LanguageType} from '../types/LanguageType'

const addressLine1Formats = {
  numberFirst: ['number', 'line1'],
  numberLast: ['line1', 'number']
}

const FALLBACK_LANGUAGE = 'en'
const FALLBACK_LOCALE = 'en-US'

const POPULAR_LANGUAGES = ['en', 'es', 'de', 'fr']

/* eslint-disable */
// prettier-ignore
/** All supported languages */
const ALL_LANGUAGES: LanguageType[] = [
  { locale: 'en-US',  code: 'en',     name: 'English (US)',                               displayName: 'English (US)',            direction: 'ltr', addressOrder: addressLine1Formats.numberFirst },
  { locale: 'en-GB',  code: 'en-GB',  name: 'English (UK)',                               displayName: 'English (UK)',            direction: 'ltr', addressOrder: addressLine1Formats.numberFirst },
  { locale: 'de-DE',  code: 'de',     name: 'German',                                     displayName: 'Deutsch',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'nl-NL',  code: 'nl',     name: 'Dutch',                                      displayName: 'Nederlands',              direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'fr-FR',  code: 'fr',     name: 'French',                                     displayName: 'Français',                direction: 'ltr', addressOrder: addressLine1Formats.numberFirst },
  { locale: 'es-ES',  code: 'es',     name: 'Spanish',                                    displayName: 'Español',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'it-IT',  code: 'it',     name: 'Italian',                                    displayName: 'Italiano',                direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'pt-PT',  code: 'pt',     name: 'Portuguese',                                 displayName: 'Português (PT)',          direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'pt-BR',  code: 'pt-BR',  name: 'Brazilian Portuguese',                       displayName: 'Português (BR)',          direction: 'ltr', addressOrder: addressLine1Formats.numberLast, fallbackLanguages: ['pt']},
  { locale: 'no-NO',  code: 'no',     name: 'Norwegian',                                  displayName: 'Norsk',                   direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'sv-SE',  code: 'sv',     name: 'Swedish',                                    displayName: 'Svenska',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'da-DK',  code: 'da',     name: 'Danish',                                     displayName: 'Dansk',                   direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'ar-SA',  code: 'ar',     name: 'Arabic',                                     displayName: ' العربية',                direction: 'rtl', addressOrder: addressLine1Formats.numberLast },
  { locale: 'ko-KR',  code: 'ko',     name: 'Korean',                                     displayName: '한국어',                    direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'he-IL',  code: 'he',     name: 'Hebrew',                                     displayName: 'עברית',                   direction: 'rtl', addressOrder: addressLine1Formats.numberFirst },
  { locale: 'pl-PL',  code: 'pl',     name: 'Polish',                                     displayName: 'Polski',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'ru-RU',  code: 'ru',     name: 'Russian',                                    displayName: 'Русский',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'tr-TR',  code: 'tr',     name: 'Turkish',                                    displayName: 'Türkçe',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'ja-JP',  code: 'ja',     name: 'Japanese',                                   displayName: '日本語',                   direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'zh-CN',  code: 'zh',     name: 'Chinese',                                    displayName: '简体中文',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'zh-HK',  code: 'zh-HK',  name: 'Hong Kong, traditional characters',          displayName: '繁體中文(香港)',            direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'zh-TW',  code: 'zh-TW',  name: 'Taiwan, traditional characters',             displayName: '繁體中文(台灣)',            direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'fi-FI',  code: 'fi',     name: 'Finnish',                                    displayName: 'Suomi',                   direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'hu-HU',  code: 'hu',     name: 'Hungarian',                                  displayName: 'Magyar',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'id-ID',  code: 'id',     name: 'Indonesian',                                 displayName: 'Bahasa Indonesia',        direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'ms-MY',  code: 'ms',     name: 'Malaysian',                                  displayName: 'Bahasa Malaysia',         direction: 'ltr', addressOrder: addressLine1Formats.numberFirst },
  { locale: 'th-TH',  code: 'th',     name: 'Thai',                                       displayName: 'ภาษาไทย',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'cs-CZ',  code: 'cs',     name: 'Czech',                                      displayName: 'Čeština',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'ro-RO',  code: 'ro',     name: 'Romanian',                                   displayName: 'Română',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'uk-UA',  code: 'uk',     name: 'Ukrainian',                                  displayName: 'Українська',              direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  // New languages added as part of 2a98dd4d-add-new-languages
  { locale: 'bn-BD',  code: 'bn',  name: 'Bengali',                                    displayName: 'বাংলা',                                direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'fil-PH', code: 'fil', name: 'Filipino',                                   displayName: 'Filipino',                            direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'fr-CA',  code: 'fr-CA',  name: 'frCA',                                       displayName: 'Français québécois',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'el-GR',  code: 'el',  name: 'Greek',                                      displayName: 'Ελληνικά',                            direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'hi-IN',  code: 'hi',  name: 'Hindi',                                      displayName: 'हिन्दी',                                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'mr-IN',  code: 'mr',  name: 'Marathi',                                    displayName: 'मराठी',                                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'vi-VN',  code: 'vi',  name: 'Vietnamese',                                 displayName: 'Vietnamese',                          direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-MX',  name: 'Español (Mexico)',                        displayName: 'Español (Mexico)',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-AR',  name: 'Español (Argentina)',                        displayName: 'Español (Argentina)',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-CL',  name: 'Español (Chile)',                            displayName: 'Español (Chile)',                     direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-CO',  name: 'Español (Colombia)',                         displayName: 'Español (Colombia)',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-CR',  name: 'Español (Costa Rica)',                       displayName: 'Español (Costa Rica)',                direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-EC',  name: 'Español (Ecuador)',                          displayName: 'Español (Ecuador)',                   direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-GT',  name: 'Español (Guatemala)',                        displayName: 'Español (Guatemala)',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-PA',  name: 'Español (Panama)',                           displayName: 'Español (Panama)',                    direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-PY',  name: 'Español (Paraguay)',                         displayName: 'Español (Paraguay)',                  direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-PE',  name: 'Español (Peru)',                             displayName: 'Español (Peru)',                      direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-PR',  name: 'Español (Puerto Rico)',                      displayName: 'Español (Puerto Rico)',               direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-DO',  name: 'Español (República Dominicana)',             displayName: 'Español (República Dominicana)',      direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-UY',  name: 'Español (Uruguay)',                          displayName: 'Español (Uruguay)',                   direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
  { locale: 'es-419', code: 'es-VE',  name: 'Español (Venezuela)',                        displayName: 'Español (Venezuela)',                 direction: 'ltr', addressOrder: addressLine1Formats.numberLast },
]
/* eslint-enable */

// Some codes were renamed & Android may still provide the old code
export const languageCodeAliases: Record<string, string> = {
  he: 'iw',
  iw: 'he',
  ji: 'yi',
  yi: 'ji',
  id: 'in',
  in: 'id'
}

const getLanguageCodes = (languages: LanguageType[]) =>
  languages.map(lang => lang.code)

const getLocaleCodes = (languages: LanguageType[]) =>
  languages.map(lang => lang.locale)

/**
 * Receives a languageCode and returns a language configuration.
 * @param languageCode The language languageCode we use in our systems
 * @returns A locale string
 */
export const getLocaleByCode = (languageCode: string) =>
  ALL_LANGUAGES.find(lang => lang.code === languageCode)?.locale ||
  FALLBACK_LOCALE

/** The default language */
export const defaultLanguage = FALLBACK_LANGUAGE
export const defaultLocale = FALLBACK_LOCALE

/** All enabled languages */
export const enabledLanguages = getLanguageCodes(ALL_LANGUAGES)

/** All enabled locales */
export const enabledLocales = getLocaleCodes(ALL_LANGUAGES)

/** All right-to-left languages */
export const rtlLanguages = getLanguageCodes(
  ALL_LANGUAGES.filter(lang => lang.direction === 'rtl')
)

/**
 * Returns whether the specified language is enabled or not.
 *
 * @returns Whether the specified language is enabled
 * @param s The language that is checked
 */
export const isEnabledLanguage = includes(__, enabledLanguages)

/**
 * Returns the mapped locale, if it is enabled. Otherwise returns the default language.
 *
 * @returns The mapped locale
 * @param locale Looks for this locale in the map
 * @param localesMap The map where the locale will be looked for
 */
export const getMappedLocaleOrDefault = (
  locale: string,
  localesMap?: Record<string, string>
): string => {
  const mappedLocale = getMappedLocale(locale, localesMap)

  return isEnabledLanguage(mappedLocale) ? mappedLocale : defaultLanguage
}

/**
 * Returns the mapped locale.
 *
 * @returns The mapped locale
 * @param locale Looks for this locale in the map
 * @param localesMap The map where the locale will be looked for
 */
export const getMappedLocale = (
  locale: string,
  localesMap?: Record<string, string>
): string => propOr(locale, locale)(localesMap)

/**
 * Returns the fallback languages for the specified language.
 * By default, returns an array with only the default language.
 *
 * @returns An array of fallback languages
 * @param languageCode The language that is looked for
 */
export const getFallbackLanguages = (languageCode: string): string[] => {
  if (languageCode === defaultLanguage) return []

  const languageObject = ALL_LANGUAGES.find(
    language => language.code === languageCode
  )
  const fallbackLanguages = languageObject?.fallbackLanguages || []

  return [...fallbackLanguages, defaultLanguage]
}

/**
 * Returns the language configuration for the specified language.
 * By default, returns the fallback language.
 *
 * @returns The language configuration
 * @param locale The locale that is looked for
 */
export const getLanguageConfig = (locale: string) => {
  return ALL_LANGUAGES.find(lang => lang.code === locale) || FALLBACK_LANGUAGE
}

/**
 * Checks if the specified locale is an RTL language.
 */
export const isRtlLanguage = (locale: string) => rtlLanguages.includes(locale)

/**
 * Determine text direction from locale (language) code
 */
export const getTextDirection = (locale: string) => {
  const isRtl = isRtlLanguage(locale)
  return {isRtl}
}

export const getLanguageCode = createSelector(
  [(state: State) => state.meta.languageCode],
  languageCode => languageCode
)

export default ALL_LANGUAGES

export const splitPopularAndOtherLanguages = (languages: LanguageType[]) => {
  const languagesList = languages.filter(
    language => !POPULAR_LANGUAGES.includes(language.code)
  )
  const popularLanguagesList = POPULAR_LANGUAGES.map(code =>
    languages.find(language => language.code === code)
  ).filter(Boolean) as LanguageType[]

  return {popularLanguagesList, languagesList}
}
