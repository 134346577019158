import {useSelector} from 'react-redux'
import {toggle} from 'opticks'

import {getIsBrandOffersLockedByDefault} from '../../_web/brand/modules/selectors'
import {hasExpectedGsiteValueInUrl} from '../../offer/business/privateDeals'
import {getUrlParams} from '../../utils/url'
import {selectIsAuthenticated} from '../modules/selectors'
import {useHasUnlockPlusTokenInUrl} from './useHasUnlockPlusTokenInUrl'
import {useIsAudience} from './useIsAudience'

export const useShouldSeeOffersUnlocked = () => {
  const brandOffersLockedByDefault = useSelector(
    getIsBrandOffersLockedByDefault
  )
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const urlParams = getUrlParams()

  const isAudience = useIsAudience()
  const hasUnlockPlusToken = useHasUnlockPlusTokenInUrl()

  // https://app.shortcut.com/findhotel/story/119964/create-audience-and-fe-changes-for-high-yield-mor-environment
  const isHighYieldEnvironmentActive = toggle(
    'sapi4eva-mor-for-ppc',
    false,
    true
  )

  return (
    isHighYieldEnvironmentActive ||
    hasUnlockPlusToken ||
    isAuthenticated ||
    isAudience ||
    hasExpectedGsiteValueInUrl(urlParams) ||
    brandOffersLockedByDefault === false
  )
}
