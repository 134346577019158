import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Icon} from '@daedalus/atlas/Icon'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {Room, RoomOffer} from '@findhotel/sapi'

import {HotelCard} from '../HotelCard'
import {AppPromotionBanner} from './AppPromotionBanner'
import {CtaButtons} from './CtaButtons'
import {GradientBackground} from './GradientBackground'
import {HeaderContent} from './HeaderContent'
import {NextSteps} from './NextSteps'

const Wrapper = styled.div`
  position: relative;
`

const ContentContainer = styled.div<{isOverlayContent?: boolean}>(
  ({theme, isOverlayContent}) => css`
    padding: ${theme.layout.spacing.s400}px
      ${isOverlayContent
        ? theme.layout.spacing.s700
        : theme.layout.spacing.s500}px
      ${theme.layout.spacing.s500}px;
    z-index: 2;
    position: relative;
  `
)
const CloseButtonWrapper = styled(Flex.Row)<{isOverlayContent?: boolean}>(
  ({theme, isOverlayContent}) => css`
    padding: ${theme.layout.spacing.s400}px
      ${isOverlayContent
        ? theme.layout.spacing.s200
        : theme.layout.spacing.s500}px
      0;
    z-index: 2;
    flex-direction: row-reverse;
  `
)

const CloseButton = styled(Button)`
  opacity: 0.6;
`

interface Props {
  expiresAt: string
  hotelName: string
  checkIn: string
  checkOut: string
  roomsSplit: string
  imageUrl: string
  rating: number | undefined
  room: Room | undefined
  errored: boolean
  offer: RoomOffer | undefined
  isOverlayContent?: boolean
  onHide: () => void
}
export const ConfirmationContent: React.FC<Props> = ({
  expiresAt,
  errored,
  hotelName,
  checkIn,
  checkOut,
  offer,
  room,
  imageUrl,
  rating,
  roomsSplit,
  isOverlayContent,
  onHide
}) => {
  return (
    <Wrapper>
      <GradientBackground />
      <CloseButtonWrapper isOverlayContent={isOverlayContent}>
        <CloseButton
          variant="transparent"
          iconStart={<Icon name={'Close'} />}
          onClick={onHide}
        />
      </CloseButtonWrapper>
      <ContentContainer isOverlayContent={isOverlayContent}>
        <HeaderContent expiresAt={expiresAt} />
        <HotelCard
          hotelName={hotelName}
          checkIn={checkIn}
          checkOut={checkOut}
          roomsSplit={roomsSplit}
          room={room}
          offer={offer}
          imageUrl={imageUrl}
          rating={rating}
          shouldShowPayNow={false}
        />
        {!errored && (
          <>
            <ContentWrapper paddingBottom={isOverlayContent ? 's400' : 's000'}>
              <NextSteps expiresAt={expiresAt} />
            </ContentWrapper>
            {!isReactNativeWebView() && (
              <ContentWrapper paddingTop="s700">
                <AppPromotionBanner />
              </ContentWrapper>
            )}
            {!isOverlayContent && (
              <CtaButtons isOverlayContent={isOverlayContent} />
            )}
          </>
        )}
      </ContentContainer>
    </Wrapper>
  )
}
