import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {useRouter} from '@daedalus/core/src/_web/router/context/RouterContext'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {useLogIn} from '../../../../../authentication/hooks/useLogIn'

interface PropsType {
  anonymousId: string
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const DiscoverGemsButton = ({anonymousId}: PropsType) => {
  const router = useRouter()
  const logIn = useLogIn()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const dispatch = useDispatch()

  const trackButtonClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.MembershipExplore,
        action: Action.Clicked,
        payload: {
          version: isAuthenticated ? 'authenticated' : 'guest'
        },
        team: Team.Retention
      })
    )
  }

  const buttonAction = () =>
    isAuthenticated
      ? router.push('/')
      : logIn({
          sourceComponent: SourceComponentType.MembershipPage,
          flow: AuthenticationFlows.SignUp,
          anonymousId
        })

  const handleButtonClick = () => {
    trackButtonClick()
    buttonAction()
  }

  return (
    <ButtonWrapper>
      <Button fullWidth variant="special" onClick={handleButtonClick}>
        <FormattedMessageWrapper
          id="membership.DiscoverGems"
          defaultMessage="Discover {gems}"
          values={{gems: 'Gems'}}
        />
      </Button>
    </ButtonWrapper>
  )
}
