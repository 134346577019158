/* eslint-disable fp/no-mutating-methods */
import {createContext, useContext} from 'react'
import {History, LocationDescriptor, Path} from 'history'
import {ParsedUrlQuery} from 'querystring'
import {UrlObject} from 'url'

export type ReactRouterUrl = Path | LocationDescriptor<History.LocationState>
export type Url = ReactRouterUrl | UrlObject

export type QueryParams = ParsedUrlQuery | {[key: string]: unknown}

export interface RouterContextProps {
  /**
   * A function that pushes a new URL to the history stack
   *
   * @param url - The URL to push
   */
  push: (url: Url) => void
  /**
   * A function that replaces the current URL in the history stack
   *
   * @param url - The URL to replace
   */
  replace: (url: Url) => void
  /**
   * A function that navigates back in the history stack
   */
  goBack: () => void
  /**
   * A function that returns the query parameters of the current URL
   *
   * @returns The query parameters
   */
  getQueryParams: () => QueryParams
}

export const RouterContext = createContext<RouterContextProps>(
  {} as RouterContextProps
)
/**
 * Hook to get the router context
 *
 * @returns The router context
 */
export const useRouter = () => useContext(RouterContext)
