import {useRouter} from '../../router/context/RouterContext'

export type ReferralInfo = {
  /**
   * Referrer account user id
   */
  referrerAccountUserId: string | undefined
  /**
   * Is referrer signed in?
   */
  isReferrerSusi: boolean
}

/**
 * Hook to get from query params the referrer `userId` and if referrer is signed in
 *
 * @returns an object containing a string `referrerAccountUserId` and a boolean `isReferrerSusi`
 */
export const useReferralInfo = (): ReferralInfo => {
  const {getQueryParams} = useRouter()
  const queryString = getQueryParams()
  const referrerAccountUserId = queryString.referrer as string | undefined
  const isReferrerSusi = Boolean(referrerAccountUserId)

  return {referrerAccountUserId, isReferrerSusi}
}
