// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgPerson360 = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.257 4.068a2.743 2.743 0 1 1 4.564 2.051c.762.216 1.369.592 1.819 1.144.738.904.931 2.136.931 3.433a.686.686 0 0 1-.685.686h-1.143v2.057a2.514 2.514 0 0 1-2.514 2.514H9.77a2.514 2.514 0 0 1-2.514-2.514v-2.057H6.114a.686.686 0 0 1-.685-.686c0-1.297.193-2.529.931-3.433.45-.552 1.057-.928 1.82-1.144a2.736 2.736 0 0 1-.923-2.051ZM10 2.696a1.371 1.371 0 1 0 0 2.743 1.371 1.371 0 0 0 0-2.743Zm-3.176 7.315h1.119c.379 0 .686.307.686.685v2.743c0 .631.511 1.143 1.142 1.143h.458c.63 0 1.142-.512 1.142-1.143v-2.743c0-.378.307-.685.686-.685h1.12c-.064-.85-.258-1.462-.6-1.881-.401-.492-1.132-.862-2.577-.862s-2.176.37-2.577.862c-.342.42-.535 1.03-.6 1.88Z"
      fill="#6C6770"
    />
    <path
      d="M16.629 15.672c0 .07-.039.232-.327.472-.285.236-.737.479-1.357.696-1.233.433-2.982.713-4.945.713-1.964 0-3.712-.28-4.945-.713-.62-.217-1.072-.46-1.357-.696-.288-.24-.327-.403-.327-.472 0-.062.03-.206.296-.426.265-.22.691-.45 1.285-.667a.686.686 0 1 0-.472-1.287c-.664.243-1.253.538-1.688.899-.436.36-.792.854-.792 1.481 0 .64.37 1.152.823 1.527.455.378 1.074.688 1.778.935 1.414.497 3.323.791 5.399.791s3.985-.294 5.4-.79c.703-.248 1.321-.558 1.777-.936.452-.375.823-.888.823-1.527 0-.582-.308-1.07-.703-1.442-.398-.374-.939-.686-1.558-.932a.686.686 0 0 0-.507 1.275c.519.206.893.437 1.125.656.235.22.272.373.272.443Z"
      fill="#6C6770"
    />
  </svg>
)

export default SvgPerson360
