// TODO (Core): Add unit tests and documentation for these functions
interface PartialWindowSafari {
  pushNotification: {
    permission: string
    requestPermission: (callback: (permission: string) => void) => void
  }
}

declare global {
  interface Window {
    safari: PartialWindowSafari
  }
}

export const isBrowser: boolean =
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  typeof navigator !== 'undefined'

export const isSafari: boolean =
  isBrowser &&
  'safari' in window &&
  typeof window.safari === 'object' &&
  'pushNotification' in window.safari
