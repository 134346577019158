/* eslint-disable fp/no-mutating-methods */
import {toggle} from 'opticks'

import {parseUrl} from '@daedalus/core/src/utils/url'

interface OfferCheckValidationError {
  message: string
}

interface OfferCheckValidationResult {
  isValid: boolean
  validationErrors: OfferCheckValidationError[]
}

// List of required url params for offer-check call
// https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/offer_check.md

const requiredParamsMor = ['mbm', 'sg', 'nc', 'exp']

// Regex to validate url path. Path example: /v2/offers/1da490c0-3e05-48bb-b176-db670eaaf565/check
const urlPathRegex = new RegExp(/.*\/offers\/[a-zA-Z\d-]+\/check/)

export const validateOfferCheckUrl = (
  offerCheckUrl: string,
  validateMorParams = false
): OfferCheckValidationResult => {
  const validationErrors = []
  const tokenPath = parseUrl(offerCheckUrl).pathname
  const requestParams = parseUrl(offerCheckUrl).searchObject

  if (!urlPathRegex.test(tokenPath)) {
    validationErrors.push({
      message: `Invalid offer check url path ${tokenPath}. It should match the following pattern: ${urlPathRegex.toString()}`
    })
  }

  // List of required url params for offer-check call
  // https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/offer_check.md
  const requiredParams = toggle(
    '82b4ae5a-stop-sending-user-location-params-in-offer-check-v2',
    [
      'anonymousId',
      'currency',
      'deviceType',
      'redirectId',
      'searchId',
      'userAgent',
      'userCountry',
      'userIp'
    ],
    ['anonymousId', 'currency', 'deviceType', 'redirectId', 'searchId']
  )

  const paramsToCheck = [
    ...requiredParams,
    ...(validateMorParams ? requiredParamsMor : [])
  ]
  const invalidParams: string[] = []

  paramsToCheck.forEach(param => {
    if (!requestParams[param]) {
      invalidParams.push(param)
    }
  })

  if (invalidParams.length > 0) {
    validationErrors.push({
      message: `Missing or invalid offer check params: ${invalidParams.join(
        ', '
      )}`
    })
  }

  return {
    isValid: validationErrors.length === 0,
    validationErrors
  }
}
