import {assocPath, equals, filter, find, head, pathOr, propEq} from 'ramda'

import {TotalPrice} from '@daedalus/core/src/api-types/bovio/response/split_bookings/offer_check'
import {
  HotelFeeBreakdownType,
  Offer,
  OfferPrice
} from '@daedalus/core/src/offer/types/offer'
import PriceTypes from '@daedalus/core/src/offer/types/PriceTypes'
import {RaaAnchorPriceBreakdownType} from '@daedalus/core/src/offer/types/Raa'
import {TaxDisplayLogic} from '@daedalus/core/src/offer/types/TaxDisplayLogic'
import {Price} from '@daedalus/core/src/price/business/price'
import {
  getDisplayPrice,
  getTotalPrice,
  isThereACurrencyConversion
} from '@daedalus/core/src/price/business/price'
import {toFloatWithTwoDecimals} from '@daedalus/core/src/utils/number'
import {RoomPrice} from '@findhotel/sapi'

import calculateDisplayTotal from '../business/calculateDisplayTotal'
import {
  getHotelFees,
  getHotelFeesBreakdown,
  nightlyDisplayTotalPath
} from '../modules/rooms/selectors'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DefaultValueType = any

type GetPricePropsType = (
  prices: OfferPrice[],
  propertyPath?: string[],
  defaultValue?: DefaultValueType
) => OfferPrice

type GetPriceByTypePropsType = (
  type: string,
  prices: OfferPrice[],
  propertyPath?: string[],
  defaultValue?: DefaultValueType
) => OfferPrice | DefaultValueType

export const HOTEL_FEES_RESORT_FEE = 'resort_fee'
export const HOTEL_FEES_TAX = 'tax'
export const HOTEL_FEES_OTHER = 'other'

export const getPriceByType: GetPriceByTypePropsType = (
  type,
  prices,
  propertyPath = [],
  defaultValue = null
) =>
  pathOr(defaultValue, [...propertyPath])(
    head(filter(propEq('type', type), prices))
  )

/** Converts price to float number */
export const convertPriceToFloat = (
  updatePath: string[],
  price: RoomPrice | OfferPrice | Price | TotalPrice
) => {
  const convertedPrice = Number.parseFloat(pathOr('', updatePath, price))
  return assocPath(updatePath, convertedPrice, price)
}

/**
  Gets the price with the type PriceTypes.CHARGEABLE_PRICE
 */
export const getChargeableCurrencyPrice: GetPricePropsType = (
  prices,
  propertyPath = []
): OfferPrice =>
  getPriceByType(PriceTypes.CHARGEABLE_PRICE, prices, propertyPath)

/**
 Returns the total hotel fees, unless it is a pay at property deal, then it returns the total price
 */
export const getTotalAtProperty = (price?: Price, isCanPayLater?: boolean) => {
  if (isCanPayLater) return getTotalPrice(price)
  return getHotelFees(price)
}

/**
 * TODO (BoFH): Once SAPI is used 100% on Checkout we should rename this function and remove the "new" postfix.
 * Finds whether a price mismatch exists between two sets of prices.
 * A price mismatch happens when the chargeable price between two sources
 * differ.
 * @param firstSourcePrices - The list of an offer's available prices from the first source to be compared
 * @param secondSourcePrices - The list of an offer's available prices from the second source to be compared
 */
export const hasAPriceMismatchBetweenTwoPriceSets = (
  firstSourcePrices: OfferPrice[],
  secondSourcePrices: OfferPrice[]
) => {
  const firstSourceChargeablePrice = getTotalPrice(
    getDisplayPrice(firstSourcePrices)
  )
  const secondSourceChargeablePrice = getTotalPrice(
    getDisplayPrice(secondSourcePrices)
  )

  return !equals(firstSourceChargeablePrice, secondSourceChargeablePrice)
}

export const calculateNightlyPrice = (
  price: number | null | undefined,
  numberOfNights: number,
  numberOfRooms = 1
) => {
  if (Number.isNaN(price)) return null

  return toFloatWithTwoDecimals(Number(price) / numberOfNights / numberOfRooms)
}

/**
Returns the price the user sees in checkout rounded.
 */
export const getNightlyDisplayTotalPrice = (prices: OfferPrice[]): number => {
  const type = isThereACurrencyConversion(prices)
    ? PriceTypes.DISPLAY_PRICE
    : PriceTypes.CHARGEABLE_PRICE

  return getPriceByType(type, prices, nightlyDisplayTotalPath, null)
}

/**
Returns the total price for all rooms, with fees, that the user sees in checkout rounded.
 */
export const getDisplayTotalPrice = (
  prices: OfferPrice[],
  numberOfRooms = 1
): number | undefined => {
  const price = getDisplayPrice(prices)
  if (!price) return
  const totalPrice = getTotalPrice(price) || 0
  return totalPrice * numberOfRooms
}

export const getTypeOfPriceMismatchWithTolerance = ({
  updatedPrice,
  originalPrice,
  tolerance
}: {
  updatedPrice: number
  originalPrice: number
  tolerance: number
}) => {
  const percentileDifference = Math.abs(
    (updatedPrice - originalPrice) / originalPrice
  )
  if (originalPrice > updatedPrice) {
    return percentileDifference > tolerance ? 'highDecrease' : 'lowDecrease'
  }

  if (originalPrice < updatedPrice) {
    return percentileDifference > tolerance ? 'highIncrease' : 'lowIncrease'
  }
}

// Anchor price should be at least 3% more expensive to be shown
// This rule is the same used in search
const ANCHOR_PRICE_RULE_PERCENTAGE = 0.03

const isAnchorPriceAboveThreshold = (anchorPrice: number, price: number) =>
  anchorPrice - Math.round(price) > anchorPrice * ANCHOR_PRICE_RULE_PERCENTAGE

export const getOfferPrices = (offer: Offer) => offer?.prices ?? []

export const shouldAnchorPriceBeVisible = (
  anchorPrice?: number | null,
  price?: number,
  cheapestOfferPrice?: number
) => {
  if (!anchorPrice || !price || !cheapestOfferPrice) {
    return false
  }

  return (
    price <= cheapestOfferPrice &&
    isAnchorPriceAboveThreshold(anchorPrice, price)
  )
}

export const shouldComparisonOfferAnchorPriceBeVisible = (
  anchorPrice?: number | null,
  price?: number
) => {
  if (!anchorPrice || !price) {
    return false
  }

  return isAnchorPriceAboveThreshold(anchorPrice, price)
}

export const getAnchorTotalPrice = (
  anchorPriceBreakdown:
    | RaaAnchorPriceBreakdownType
    | OfferPrice
    | null
    | undefined,
  numberOfRooms = 1
) => {
  if (!anchorPriceBreakdown) return 0

  const anchorTotalPrice =
    anchorPriceBreakdown.chargeable.base +
    anchorPriceBreakdown.chargeable.taxes +
    anchorPriceBreakdown.hotelFees.total

  return toFloatWithTwoDecimals(anchorTotalPrice * numberOfRooms) || 0
}

export const getTotalSaving = (
  anchorPriceBreakdown:
    | RaaAnchorPriceBreakdownType
    | OfferPrice
    | null
    | undefined,
  prices: Price | undefined,
  numberOfRooms: number
) => {
  const anchorTotalPrice = getAnchorTotalPrice(
    anchorPriceBreakdown,
    numberOfRooms
  )
  const totalPrice = getTotalPrice(prices)
  return anchorTotalPrice !== null && totalPrice !== null
    ? toFloatWithTwoDecimals(anchorTotalPrice - totalPrice)
    : 0
}

export const arePricesDifferent = (priceA: number, priceB: number) => {
  if (!priceA || !priceB || equals(priceA, priceB)) {
    return false
  }

  return true
}

export const getRaaOrSapiNightlyDisplayTotalPrice = (
  baseRate: number,
  tax: number,
  localTax: number,
  numberOfNights: number,
  numberOfRooms: number,
  taxDisplayLogic: TaxDisplayLogic
) =>
  calculateDisplayTotal(
    baseRate,
    tax,
    localTax,
    taxDisplayLogic.includeTaxes,
    taxDisplayLogic.includeLocalTaxes,
    numberOfNights,
    numberOfRooms
  )

export const getSelectedHotelFee = (price?: Price, type?: string) => {
  const hotelFeesBreakdown = getHotelFeesBreakdown(price)
  const selectedFeeType = find(propEq('type', type))(hotelFeesBreakdown)
  return pathOr(0, ['total'], selectedFeeType)
}

export const getHotelFeesResortFee = (price?: Price) =>
  getSelectedHotelFee(price, HOTEL_FEES_RESORT_FEE)

export const getHotelFeesTax = (price?: Price) =>
  getSelectedHotelFee(price, HOTEL_FEES_TAX)

export const getHotelFeesOther = (price?: Price) =>
  getSelectedHotelFee(price, HOTEL_FEES_OTHER)

/**
 * This function will verify if the chargeable and hotelFees portion of the price
 * object have each their own currencyCode. If they don't have, we will add the one from the root of the
 * object. Future changes in the API will bring this information making this function obsolete.
 * When 003bfcb2-pay-at-property-chargeable-currency-ui is complete we can remove this function
 * @param price an offer price object
 * @example
 * // This
 * {
      "chargeable": {
        "base": "378.00",
        "taxes": "49.14",
        "total": "427.14"
      },
      "currencyCode": "USD",
      "hotelFees": {
        "breakdown": [
          {
            "total": "88.14",
            "type": "resort_fee"
          }
        ],
        "total": "88.14"
      },
      "type": "chargeable_currency"
    }

    // becomes this
    {
      "chargeable": {
        "base": "378.00",
        "taxes": "49.14",
        "total": "427.14",
        "currencyCode": "USD",
      },          
      "hotelFees": {
        "breakdown": [
          {
            "total": "88.14",
            "type": "resort_fee"
          }
        ],
        "total": "88.14",
        "currencyCode": "USD"
      },
      "type": "chargeable_currency"
    }
 */
export const addCurrencyToChargeableAndHotelFees = (
  price: OfferPrice
): OfferPrice => {
  const baseCurrency = price?.currencyCode
  const chargeableCurrency = price.chargeable?.currencyCode
  const hotelFeesCurrency = price.hotelFees?.currencyCode

  let newPrice = {...price}

  if (!chargeableCurrency) {
    newPrice = assocPath(['chargeable', 'currencyCode'], baseCurrency, newPrice)
  }

  if (!hotelFeesCurrency) {
    newPrice = assocPath(['hotelFees', 'currencyCode'], baseCurrency, newPrice)
  }

  return newPrice
}

/**
 * SAPI returns no breakdown at the moment this function is being introduced (21/10/2021)
 * To avoid changing the way we calculate and display hotel fees we are picking up the total
 * and creating a generic breakdown.
 *
 * @param price an offer price object
 * @returns the price decorated with the breakdown
 */
export const guaranteeAtLeastOneHotelFeeBreakdown = (
  price: OfferPrice
): OfferPrice => {
  if (price.hotelFees?.breakdown?.length === 0 && price.hotelFees.total !== 0) {
    return {
      ...price,
      hotelFees: {
        ...price.hotelFees,
        breakdown: [
          {
            total: price.hotelFees.total,
            type: 'other' as HotelFeeBreakdownType
          }
        ]
      }
    }
  }

  return price
}
