import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAnonymousId} from 'modules/meta/selectors'
import {changeAccountVisibility} from 'modules/overlay/actions'

import {
  trackLoginClick,
  trackSignUpClick
} from '@daedalus/core/src/auth/modules/actions'
import {
  selectAuthEnabled,
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {useLogIn} from '@daedalus/shared/src/authentication/hooks/useLogIn'
import {MobileMenuUserSection} from '@daedalus/shared/src/user/account/MobileMenuUserSection'

const UserSideMenuWithStore = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const authEnabled = useSelector(selectAuthEnabled)
  const logIn = useLogIn()
  const isLoading = useSelector(selectIsLoading)

  const anonymousId = useSelector(getAnonymousId)

  const handleOnSignUpClick = () => {
    const component = SourceComponentType.PreferencesOverlay
    dispatch(trackSignUpClick(component))

    logIn({
      flow: AuthenticationFlows.SignUp,
      sourceComponent: component,
      anonymousId
    })
  }

  const handleOnLoginClick = () => {
    const component = SourceComponentType.PreferencesOverlay
    dispatch(trackLoginClick(component))

    logIn({
      flow: AuthenticationFlows.Login,
      sourceComponent: component,
      anonymousId
    })
  }

  const onShowAccountOverlay = () => {
    dispatch(changeAccountVisibility(true)())
  }

  return authEnabled ? (
    <MobileMenuUserSection
      isAuthenticated={Boolean(isAuthenticated)}
      isLoading={Boolean(isLoading)}
      onSignUpClick={handleOnSignUpClick}
      onLogInClick={handleOnLoginClick}
      onShowAccountOverlay={onShowAccountOverlay}
    />
  ) : null
}

export default UserSideMenuWithStore
