import {runInIdleTime} from '../../../_web/utils/performance/idleUntilUrgent'
import {EventPayload} from './types'

type IdentifyPayload = EventPayload & {anonymous_id: string | undefined}

export type CioClient = {
  track(track: string, payload: EventPayload): void
  identify(payload: IdentifyPayload): void
}

declare let _cio: CioClient
declare global {
  interface Window {
    _cio: typeof _cio
  }
}

export const CIO = {
  track(track: string, payload: EventPayload) {
    const trackCIO = () => {
      _cio.track(track, payload)
    }

    runInIdleTime(trackCIO)
  },
  identify(payload: IdentifyPayload) {
    const identifyCIO = () => {
      _cio.identify(payload)
    }

    runInIdleTime(identifyCIO)
  }
}

export const getCio = () => {
  return typeof _cio === 'undefined' ? undefined : CIO
}
