import {useSelector} from 'react-redux'

import {getIsBrandOffersLockedByDefault} from '@daedalus/core/src/_web/brand/modules/selectors'
import {
  getIsAuthenticated,
  getIsMemberPlus,
  selectIsEmployee
} from '@daedalus/core/src/auth/modules/selectors'
import {getCugDealsParameter} from '@daedalus/core/src/offer/business/getCugDealsParameter'
import {getUserTierForSapi} from '@daedalus/core/src/sapi/utils'

const TEMPORARY_URL_PARAMS = [
  'scrollAnchorId',
  'anonymousId',
  'openHotelDetails'
]

const getSerializedUrlParamsWithoutTempParams = (locationSearch: string) => {
  const params = new URLSearchParams(locationSearch)

  TEMPORARY_URL_PARAMS.forEach(tempParam => {
    params.delete(tempParam)
  })

  return params
}

export const useGetSapiCommonParams = () => {
  const urlParams = new URLSearchParams(
    getSerializedUrlParamsWithoutTempParams(window.location.search)
  )
  const isMemberPlus = getIsMemberPlus()
  const isEmployee = useSelector(selectIsEmployee)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isBrandOffersLockedByDefault = useSelector(
    getIsBrandOffersLockedByDefault
  )

  const cugDeals = getCugDealsParameter({
    isMemberPlus,
    isEmployee,
    urlParams: Object.fromEntries(urlParams),
    isBrandOffersLockedByDefault
  }).join(',')

  const tier = getUserTierForSapi({
    isAuthenticated,
    isMemberPlus,
    isBrandOffersLockedByDefault
  })

  return {
    tier,
    cugDeals
  }
}
