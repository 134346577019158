/* eslint-disable fp/no-mutation */
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mergeRight, pipe} from 'ramda'

import {CreditCard} from '@daedalus/core/src/booking/services/paymentOptionsApi'
import {getTaxDisplayLogic} from '@daedalus/core/src/localization/business/countries'
import {sortRoomsByNightlyDisplayTotalAsc} from '@daedalus/core/src/room/business/utils/sortRooms'
import {SplitBooking} from '@daedalus/core/src/room/types/room'
import {Room} from '@daedalus/core/src/room/types/room'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'
import {Room as SapiRoom} from '@findhotel/sapi'

import {RoomsHttpResponseType} from '../../../types/Rooms'
import {urlParams} from '../../utils/urlParams'
import roomsEndpointResponseToRoomsState from './adapters/roomsEndpointResponseToRoomsState'
import {decorateSplitBookingPrices, formatPrices} from './formatPrices'

export interface PositionsType {
  selectedRoomPosition?: number
  selectedOfferPosition?: number
}

export interface RoomsInfoType {
  checkInInstructions: string
  creditCardTypes?: CreditCard[]
  hotelId: string
  searchId: string
}

interface TaxDisplayLogic {
  includeLocalTaxes: boolean
  includeTaxes: boolean
}

export interface RoomsStateType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: Array<Record<string, any>>
  fetchPaymentState: string
  fetchRoomsState: string
  selectedOfferCheckoutUrl: string | null
  roomsInfo: RoomsInfoType | null
  roomsList: Room[] | null | undefined
  taxDisplayLogic?: TaxDisplayLogic
  selectedRoomPosition?: number
  selectedOfferPosition?: number
  splitBooking?: SplitBooking
}

export const initialState: RoomsStateType = {
  roomsInfo: null,
  roomsList: [],
  selectedOfferCheckoutUrl: null,
  fetchRoomsState: '',
  fetchPaymentState: '',
  errors: [],
  taxDisplayLogic: getTaxDisplayLogic(urlParams().userCountry)
}

const {actions, reducer} = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setSelectedOfferCheckoutUrl: (
      state: RoomsStateType,
      action: PayloadAction<string | null>
    ) => {
      state.selectedOfferCheckoutUrl = action.payload
    },
    clearSelectedOfferCheckoutUrl: (state: RoomsStateType) => {
      state.selectedOfferCheckoutUrl = null
    },
    roomsFetch: (state: RoomsStateType, action) => {
      state.fetchRoomsState = action.type
    },
    roomsFetchPending: (state: RoomsStateType, action) => {
      state.fetchRoomsState = action.type
    },
    roomsFetchDone: (
      state: RoomsStateType,
      action: PayloadAction<RoomsHttpResponseType>
    ) => {
      const roomsData = roomsEndpointResponseToRoomsState(action.payload.data)
      const decoratedRoomsList = roomsData.roomsList
        ? pipe<[SapiRoom[]], Room[], Room[]>(
            roomsList => formatPrices(roomsList, state),
            roomsList => sortRoomsByNightlyDisplayTotalAsc(roomsList)
          )(roomsData.roomsList)
        : undefined

      const decoratedSplitBooking = roomsData.splitBooking
        ? decorateSplitBookingPrices(
            roomsData.splitBooking,
            state.taxDisplayLogic
          )
        : undefined

      const updatedRoomsInfo = mergeRight(
        state.roomsInfo ? state.roomsInfo : {},
        roomsData.roomsInfo
      )

      state.roomsList = decoratedRoomsList
      state.roomsInfo = updatedRoomsInfo
      state.fetchRoomsState = action.type
      state.splitBooking = decoratedSplitBooking
    },
    roomsFetchError: (state: RoomsStateType, action) => {
      state.fetchRoomsState = action.type
    },
    roomsFetchFatalError: (state: RoomsStateType, action) => {
      state.fetchRoomsState = action.type
    },
    setSelectedRoomAndOfferPosition: (
      state: RoomsStateType,
      action: PayloadAction<PositionsType>
    ) => {
      state.selectedOfferPosition = action.payload?.selectedOfferPosition
      state.selectedRoomPosition = action.payload?.selectedRoomPosition
    },
    clearRoomResults: (state: RoomsStateType) => {
      state.roomsList = []
      state.roomsInfo = null
      state.fetchRoomsState = ''
    },
    changeRoomsConfig: (
      state: RoomsStateType,
      action: PayloadAction<string>
    ) => {
      state.fetchRoomsState = action.type
    },
    changeSearchParameters: (
      state: RoomsStateType,
      action: PayloadAction<
        Pick<UrlCheckoutParamsType, 'checkIn' | 'checkOut' | 'rooms'>
      >
    ) => {
      state.fetchRoomsState = action.type
    },
    markOfferAsSoldOut: (
      state: RoomsStateType,
      action: PayloadAction<{roomId?: string; offerId: string}>
    ) => {
      const {roomId, offerId} = action.payload
      if (state.roomsList) {
        for (const room of state.roomsList) {
          if (room.id === roomId) {
            for (const offer of room.offers) {
              if (offer.id === offerId) {
                offer.isSoldOut = true
              }
            }
          }
        }
      }
    }
  }
})

export const changeDates = createAction<{checkIn: string; checkOut: string}>(
  'rooms/changeDates'
)

export const {
  setSelectedOfferCheckoutUrl,
  clearSelectedOfferCheckoutUrl,
  roomsFetchPending,
  roomsFetchDone,
  roomsFetch,
  roomsFetchError,
  roomsFetchFatalError,
  setSelectedRoomAndOfferPosition,
  clearRoomResults,
  changeRoomsConfig,
  changeSearchParameters,
  markOfferAsSoldOut
} = actions

export type RoomsActions = typeof actions

export default reducer
