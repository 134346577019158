import {useLocation} from 'react-router'
import {assocPath, Path} from 'ramda'

import {useRouter} from '../context/RouterContext'

/**
 * Hook to update the location state
 *
 * @returns A function that updates the route with the new state
 */
export const useUpdateLocationState = () => {
  const location = useLocation<object>()
  const router = useRouter()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (path: Path, newState: Record<string, any>) => {
    const newLocation = assocPath(path, newState, location)
    router.push(newLocation)
  }
}
