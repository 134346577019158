import {
  getCookie,
  removeCookie,
  setCookie
} from '@daedalus/core/src/utils/cookies'
import {getUrlParam} from '@daedalus/core/src/utils/url'

import {CookieConfig} from '../../../../../types/Cookie'
import {
  APPath,
  BMPath,
  CPPath,
  HPPath,
  MBPath,
  NPSPath,
  PPPath,
  RSPPath,
  SIMULATE_PARAM,
  SRPPath
} from '../../constants'
import {Pages} from '../../types/enums'
import {ScenarioConfig, ScenarioMetadata} from '../../types/types'
import {simulationConfig} from './cookieConfig'

const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}
export const getPersistedSimulationFlag = () => {
  const simulationFlag = getCookie(simulationConfig.name)
  return simulationFlag
}

export const persistSimulation = (simulationFlag: string) => {
  setCookie(
    simulationConfig.name,
    simulationFlag,
    getCookieOptions(simulationConfig)
  )
}

export const removeSimulation = () => {
  removeCookie(simulationConfig.name)
}

export const buildScenarioFlag = (
  scenarioValue: string,
  variation?: string
) => {
  return variation
    ? `${scenarioValue}${variation && `.${variation}`}`
    : scenarioValue
}

export const parseScenarioFlag = (
  combined: string
): {scenarioValue: string; variation: string | null} => {
  const parts = combined.split('.')

  return {
    scenarioValue: parts[0],
    variation: parts.length > 1 ? parts.slice(1).join('.') : null
  }
}

export const getUrlSimulationFlag = () => {
  return getUrlParam({
    search: window.location.search,
    param: SIMULATE_PARAM
  })
}

export const getCurrentPage = () => {
  const pathname = window.location.pathname
    .replace(/\/$/, '')
    .replace(/[0-9]/g, '') // Strip trailing slashes and hotel id
  switch (pathname) {
    case HPPath:
      return Pages.HP
    case SRPPath:
      return Pages.SRP
    case RSPPath:
      return Pages.RSP
    case APPath:
      return Pages.AP
    case PPPath:
      return Pages.PP
    case CPPath:
      return Pages.CP
    case BMPath:
      return Pages.BM
    case MBPath:
      return Pages.MB
    case NPSPath:
      return Pages.NPS

    default:
      break
  }
}

export const jsCallbackHandler =
  (route: ScenarioConfig, callback) => (req, res, ctx) => {
    if (route.page === getCurrentPage()) {
      return callback(req, res, ctx)
    }
  }

export const jsonCallbackHandler =
  (route: ScenarioConfig, response) => async (req, res, ctx) => {
    if (route.page === getCurrentPage()) {
      return res(ctx.status(200), ctx.json(response))
    }
  }

export const registerHandlers = (
  scenarioMetadata: ScenarioMetadata,
  paramName: string,
  variation: string,
  loadScenarioRouteIntoHandlers
) => {
  return scenarioMetadata.routes.map(async route => {
    const scenarioFilename = `${route.method}${route.endpoint}${route.page}`
    const variationFilename = `${scenarioFilename}.${variation}`

    try {
      if (variation) {
        return await loadScenarioRouteIntoHandlers(
          paramName,
          variationFilename,
          route,
          variation
        )
      }
    } catch {
      return await loadScenarioRouteIntoHandlers(
        paramName,
        scenarioFilename,
        route,
        variation
      )
    }

    return await loadScenarioRouteIntoHandlers(
      paramName,
      scenarioFilename,
      route,
      variation
    )
  })
}

export const getSimulationFlags = (fullFlag: string): string[] => {
  return fullFlag.split('.')
}

export const getAllVariationsFromMetadata = (
  metadata: ScenarioMetadata
): string[] => {
  let variations: Iterable<string> = []
  metadata?.routes.forEach(
    // @ts-ignore
    // eslint-disable-next-line fp/no-mutation
    route => (variations = [...variations, ...(route.variations ?? [])])
  )
  const dedupedVariations = [...new Set(variations)]
  return dedupedVariations.length ? dedupedVariations : []
}
