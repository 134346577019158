import React from 'react'
import {css, Global} from '@emotion/react'
import {ThemeType} from 'types/Theme'

export const CssBaseline = () => {
  return (
    <>
      {/* @ts-expect-error as Global treats theme type only as Theme */}
      <Global<ThemeType>
        styles={(theme: ThemeType) => css`
          html {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            box-sizing: border-box;
            line-height: ${theme.fonts.lineHeight.base};
            font-size: ${theme.fonts.size.base};
            -webkit-text-size-adjust: 100%;
            height: 100%;
          }
          *,
          ::after,
          ::before {
            box-sizing: inherit;
          }
          body {
            margin: 0;
            background-color: ${theme.colors.background.neutral.c000};
            font-family: ${theme.fonts.family.default};
            font-size: ${theme.fonts.size.sm};
            color: ${theme.colors.content.neutral.c950};
            overflow-x: hidden;
            height: 100%;
          }
          h1 {
            ${theme.typography.text.title2XL};
          }
          h2 {
            ${theme.typography.text.titleL};
          }
          h3 {
            ${theme.typography.text.titleM};
          }
          h4 {
            ${theme.typography.text.titleS};
            margin: 0;
          }
          h5 {
            ${theme.typography.text.titleXS};
            margin: 0;
          }
          hr {
            box-sizing: content-box;
            height: 0;
            overflow: visible;
          }
          pre {
            font-family: monospace, monospace;
            font-size: 1rem;
          }
          a {
            ${theme.typography.link.default};
          }
          abbr[title] {
            border-bottom: none;
            text-decoration: underline;
            text-decoration: underline dotted;
          }
          b,
          strong {
            font-weight: bolder;
          }
          code,
          kbd,
          samp {
            font-family: monospace, monospace;
            font-size: 1em;
          }
          small {
            font-size: 80%;
          }
          sub,
          sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
          }
          sub {
            bottom: -0.25em;
          }
          sup {
            top: -0.5em;
          }
          img {
            border-style: none;
          }
          button,
          input,
          optgroup,
          select,
          textarea {
            font-family: inherit;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;
          }
          button,
          input {
            overflow: visible;
          }
          button,
          select {
            text-transform: none;
          }
          button,
          [type='button'],
          [type='reset'],
          [type='submit'] {
            -webkit-appearance: button;
          }
          button::-moz-focus-inner,
          [type='button']::-moz-focus-inner,
          [type='reset']::-moz-focus-inner,
          [type='submit']::-moz-focus-inner {
            border-style: none;
            padding: 0;
          }
          button:-moz-focusring,
          [type='button']:-moz-focusring,
          [type='reset']:-moz-focusring,
          [type='submit']:-moz-focusring {
            outline: 1px dotted ButtonText;
          }
          fieldset {
            padding: 0.35em 0.75em 0.625em;
          }
          legend {
            box-sizing: border-box;
            color: inherit;
            display: table;
            max-width: 100%;
            padding: 0;
            white-space: normal;
          }
          progress {
            vertical-align: baseline;
          }
          textarea {
            overflow: auto;
          }
          [type='checkbox'],
          [type='radio'] {
            box-sizing: border-box;
            padding: 0;
          }
          [type='number']::-webkit-inner-spin-button,
          [type='number']::-webkit-outer-spin-button {
            height: auto;
          }
          [type='search'] {
            -webkit-appearance: none;
            outline-offset: -2px;
          }
          [type='search']::-webkit-search-decoration {
            -webkit-appearance: none;
          }
          ::-webkit-file-upload-button {
            -webkit-appearance: button;
            font: inherit;
          }
          details {
            display: block;
          }
          summary {
            display: list-item;
          }
          template {
            display: none;
          }
          [hidden] {
            display: none;
          }

          .grecaptcha-badge {
            visibility: hidden;
          }

          // Remove automatic tap highlight on mobile devices
          * {
            -webkit-tap-highlight-color: ${theme.colors.transparent};
          }
          div#__next {
            height: 100%;
          }
          @media print {
            @page {
              margin-top: 0;
              margin-bottom: 0;
            }
            body {
              padding-top: 30px;
              padding-bottom: 30px;
            }
          }
        `}
      />
    </>
  )
}
