import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import useParams from 'components/data/URLParams/useParams'
import {changePreferences} from 'modules/localization/actions/changePreferences'
import Settings from 'Settings'

import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {getEnabledCurrencies} from '@daedalus/core/src/localization/business/currency'
import {
  PreferencesWidget,
  SelectedElement
} from '@daedalus/shared/src/user/preferences/PreferencesWidget'

interface Props {
  hasCurrency?: boolean
  hasLanguage?: boolean
  selectedCurrency?: string
  onCurrencyChange?: (currency: string) => void
}

export const PreferencesWidgetWithStore = ({
  hasCurrency,
  selectedCurrency,
  hasLanguage = true,
  onCurrencyChange
}: Props) => {
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()

  const isAuthenticated = useSelector(selectIsAuthenticated)

  const urlParams = useParams()
  const {currency: urlCurrency} = urlParams
  const {locale} = useIntl()
  const currency =
    onCurrencyChange && selectedCurrency ? selectedCurrency : urlCurrency
  const enabledCurrencies = getEnabledCurrencies(
    Settings.get('REACT_APP_ENABLED_CURRENCIES')
  )

  const handlePreferencesChange = useCallback(
    async ({hasCurrencyChanged, hasLanguageChanged, currency, language}) => {
      if (hasLanguageChanged) {
        dispatchTeamEvent(
          trackEvent({
            category: Category.User,
            entity: Entity.LanguageChange,
            action: Action.Submitted
          })
        )
      }

      if (hasCurrencyChanged) {
        dispatchTeamEvent(
          trackEvent({
            category: Category.User,
            entity: Entity.CurrencyChange,
            action: Action.Submitted
          })
        )
      }
      if (onCurrencyChange) {
        if (hasLanguageChanged) {
          dispatch(
            changePreferences({
              language
            })
          )
        } else if (hasCurrencyChanged) {
          onCurrencyChange?.(currency)
        }
      } else {
        setTimeout(() => {
          dispatch(
            changePreferences({
              currency: hasCurrencyChanged ? currency : undefined,
              language: hasLanguageChanged ? language : undefined
            })
          )
        }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
      }
    },
    [dispatch]
  )
  const handlePreferencesLinkClick = useCallback(() => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.LanguageCurrencyPreferencesButton,
        action: Action.Clicked
      })
    )
  }, [dispatchTeamEvent])

  const handleSelectClick = useCallback(
    (selectedElement: SelectedElement) => {
      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.LanguageCurrencyPreferencesDropdownMenu,
          action: Action.Clicked,
          team: Team.Select,
          payload: {
            selectedElement
          }
        })
      )
    },
    [dispatchTeamEvent]
  )

  return (
    <PreferencesWidget
      hasLanguage={hasLanguage}
      currentCurrency={currency}
      currentLanguage={locale}
      enabledCurrencies={enabledCurrencies}
      isAuthenticated={isAuthenticated}
      hasCurrency={hasCurrency}
      onPreferencesChange={handlePreferencesChange}
      onPreferencesLinkClick={handlePreferencesLinkClick}
      onSelectClick={handleSelectClick}
    />
  )
}
