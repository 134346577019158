import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {PreferencesWidgetWithStore} from 'components/PreferencesWidgetWithStore'
import {SearchBox} from 'components/SearchBox'
import UserWidgetWithStore from 'components/UserWidgetWithStore'
import {getCurrentBrand} from 'configs/brand'
import Settings from 'Settings'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Header} from '@daedalus/atlas/Header'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {SourceComponentType} from '@daedalus/core/src/auth/types/Auth'

interface Props {
  /** Disable the default logo click which redirects to home */
  disableLogoClick: boolean
  disablePreferences: boolean
  hasSearchForm: boolean
  hasCurrencySelector?: boolean
  hasUserWidget: boolean
  hideMyBookings?: boolean
  hideManageAccount?: boolean
  selectedCurrency?: string
  page?: Page
  onCurrencyChange?: (currency: string) => void
}

const SearchBoxDesktopLayout = styled('div')(
  ({theme}) => css`
    padding: 0 ${theme.layout.spacing.s600}px;
    margin: 0 auto;
  `
)

const SearchBoxDesktopBottomLayout = styled(SearchBoxDesktopLayout)(
  ({theme}) => css`
    padding-bottom: ${theme.layout.spacing.s500}px;
  `
)

const SearchBoxDesktopSize = styled('div')`
  width: 100%;
  max-width: 800px;
`

const RightComponentWrapper = styled('div')(
  ({theme}) => css`
    display: flex;
    padding-right: ${theme.layout.spacing.s600}px;
    gap: ${theme.layout.spacing.s300}px;
  `
)

export const DesktopHeader = ({
  disableLogoClick,
  disablePreferences,
  hasSearchForm,
  hasCurrencySelector,
  selectedCurrency,
  page,
  hasUserWidget = true,
  hideMyBookings = false,
  hideManageAccount = false,
  onCurrencyChange
}: Props) => {
  const brand = getCurrentBrand()
  const {isDesktopSm, isDesktopXs} = useDeviceLayout()
  const searchBoxShouldMoveToBottomComponent =
    Boolean(isDesktopXs || isDesktopSm) && hasSearchForm

  const isPaymentPage = page === Page.Payment

  return (
    <Header
      showLogo={true}
      brand={brand}
      hasShadow={false}
      hasDivider={true}
      disableLogoClick={disableLogoClick}
      logoUrl={Settings.get('REACT_APP_VIO_SEARCH_URL')}
      CenterComponent={
        hasSearchForm &&
        !searchBoxShouldMoveToBottomComponent && (
          <SearchBoxDesktopLayout>
            <SearchBoxDesktopSize>
              <SearchBox />
            </SearchBoxDesktopSize>
          </SearchBoxDesktopLayout>
        )
      }
      RightComponent={
        <RightComponentWrapper>
          {!disablePreferences && (
            <PreferencesWidgetWithStore
              hasLanguage={!isPaymentPage}
              hasCurrency={hasCurrencySelector}
              selectedCurrency={selectedCurrency}
              onCurrencyChange={onCurrencyChange}
            />
          )}
          {hasUserWidget && (
            <UserWidgetWithStore
              hideMyBookings={hideMyBookings}
              hideManageAccount={hideManageAccount}
              referralComponent={
                isPaymentPage ? SourceComponentType.PaymentPage : undefined
              }
            />
          )}
        </RightComponentWrapper>
      }
      BottomComponent={
        searchBoxShouldMoveToBottomComponent && (
          <SearchBoxDesktopBottomLayout>
            <SearchBox />
          </SearchBoxDesktopBottomLayout>
        )
      }
    />
  )
}
