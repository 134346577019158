import {bookApi} from '@daedalus/core/src/booking/services/bookApi'
import {BookingData} from '@daedalus/core/src/booking/types/BookingData'
import {BookingRequest} from '@daedalus/core/src/booking/types/BookingRequest'
import {BookingStatus} from '@daedalus/core/src/booking/types/BookingStatus'

import {ModuleActionType} from './actions'
import {bookOfferFinalizationError} from './actions/bookOfferFinalization'
import {BOOK_OFFER_PSD2_ERROR} from './actions/bookOfferPsd2'
import sliceReducer from './slice'

export interface BookStateType {
  booking: {
    id?: string
    status?: BookingStatus
    request: BookingRequest | null | undefined
    response: BookingData | null
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: Array<Record<string, any>>
  offerBookState: string | null
}

export const initialState: BookStateType = {
  booking: {
    id: undefined,
    request: null,
    response: null,
    status: undefined
  },
  offerBookState: null,
  errors: []
}

export const basePath = ['chargeable', 'base']
export const taxesPath = ['chargeable', 'taxes']
export const hotelFeesPath = ['hotelFees', 'total']

const bookReducer = (
  state: BookStateType = initialState,
  action: ModuleActionType
) => {
  if (bookApi.endpoints.createBooking.matchPending(action)) {
    return {
      ...state,
      offerBookState: ''
    }
  }

  switch (action.type) {
    case bookOfferFinalizationError.type:
    case BOOK_OFFER_PSD2_ERROR: {
      return {
        ...state,
        offerBookState: action.type
      }
    }

    default: {
      return sliceReducer(state, action)
    }
  }
}

export default bookReducer
