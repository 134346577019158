import {CancellationPenalties, RoomsSplitBooking} from '@findhotel/sapi'

import {Guests} from '../../booking/types/BookingData'
import {Offer, OfferPrice} from '../../offer/types/offer'

export enum PostBookingBoardType {
  Breakfast = 'breakfast',
  Lunch = 'lunch',
  Dinner = 'dinner',
  FullBoard = 'full_board',
  HalfBoard = 'half_board',
  AllInclusive = 'all_inclusive',
  RoomOnly = 'room_only'
}

/**
 * Represents the types of bed available for the user (Single, Double, Cot)
 */
export interface BedType {
  id: string
  name: string
}

/**
 * Each {@link Room} can contain a list of images to illustrate where the user might stay
 */
export interface RoomImage {
  thumbnailUrl?: string
  url: string
}

export interface RoomArea {
  squareMeters?: number
  squareFeet?: number
}

/**
 * A {@link Room} type represents a real room in an accomodation that is possible to be booked.
 * It contains among other things a list of {@link Offer}
 */
export interface Room {
  /** Ammenities present in the room. */
  amenities: string[]
  bedTypes: BedType[]
  /** The room long description. */
  description: string
  id: string
  /** Room master ID is created when multiple rooms are merged/squashed into one. */
  masterId: string
  images: RoomImage[]
  /** The name of the room listed in the room list. */
  name: string
  /** How many people fit in the room */
  occupationPerRoom: number
  /** The list of possible offers for that room. */
  offers: Offer[]
  /** Whether that room can be chosen for smokers. */
  smokingOptionsAvailable?: boolean
  /** Number of guests */
  guests?: Guests
  /** Guest's first name */
  firstName?: string
  /** Guest's last name */
  lastName?: string
  /** Room Meal type */
  boardType?: PostBookingBoardType
  /** Ids of other rooms sqhashed into current one */
  squashedIds: string[]
  /** Whether the room has an offer that was selected by the user on SRP. Added for sapi4eva-match-clicked-offer experiment */
  hasClickedOffer?: boolean
  /** The name of the RAA */
  raaName?: string
  /** The room area square in meters and feet */
  area?: RoomArea
}

export type RoomWithoutOffers = Omit<Room, 'offers' | 'squashedIds'>

export interface SplitBookingOffer
  extends Omit<RoomsSplitBooking['offers'][number], 'offer'> {
  offer: Offer
}

export interface SplitBooking
  extends Omit<RoomsSplitBooking, 'offers' | 'prices'> {
  offers: Array<SplitBookingOffer>
  prices: OfferPrice[]
}

export interface AnalyticsSplitBooking extends SplitBooking {
  isHidden: boolean
}

export interface RoomSplitBookingContext extends SplitBooking {
  isHidden?: boolean
}

export type SplitBookingDates = {
  checkIn: string
  splitDate: string
  checkOut: string
}

export enum SapiAbstractOfferType {
  Regular = 'regular',
  SplitBooking = 'splitBooking'
}

export type SapiAbstractOffer =
  | {
      id: string
      type: SapiAbstractOfferType.Regular
      data: Room
    }
  | {
      id: string
      type: SapiAbstractOfferType.SplitBooking
      data: SplitBooking
    }
/* temporary type until migration to SB v3
 * [Booking core xp] clean this up in sc-128234
 */
export interface SplitBookingMerged extends SplitBooking {
  cancellationPenalties?: CancellationPenalties[]
}
