import React from 'react'
import {Offline} from 'react-detect-offline'
import {css, Global} from '@emotion/react'
import styled from '@emotion/styled'
import ToasterMessage from 'components/presentational/ToasterMessage'
import {ThemeType} from 'types/Theme'

import {Spinner} from '@daedalus/atlas/Spinner'
import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

const SpinnerWrapper = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    margin: 0 ${theme.layout.spacing.s300}px 0 ${theme.layout.spacing.s500}px;
  `
)

const NetworkMonitor = () => {
  return (
    <Offline polling={false}>
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      <ToasterMessage>
        <Text>
          <FormattedMessageWrapper
            id="offlineMessage"
            defaultMessage="No internet connection. We are trying to reconnect…"
          />
        </Text>
        <SpinnerWrapper>
          <Spinner size={24} colorPath="content.neutral.c000" />
        </SpinnerWrapper>
      </ToasterMessage>
    </Offline>
  )
}

export default NetworkMonitor
