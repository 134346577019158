import React, {useEffect} from 'react'
import {BookingError} from 'hooks/useBookingError'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import {ErrorCategory} from '@daedalus/core/src/booking/types/ErrorCategory'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {useScrollTo} from '@daedalus/core/src/utils/dom/hooks/useScrollTo'
import {ErrorBanner} from '@daedalus/shared/src/booking/ErrorBannerRedesigned'

export const getIsWrongCCError = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.IncorrectNumber
}

export const WrongCCNumber = React.memo(() => {
  const {ref, scroll} = useScrollTo(500, 100)

  useEffect(() => {
    scroll()
  }, [])

  return (
    <ContentWrapper paddingBottom="s600">
      <div ref={ref}>
        <ErrorBanner
          title={
            <FormattedMessageWrapper
              id="errors.incorrectCCTitle"
              defaultMessage="Incorrect card number"
            />
          }
        >
          <Text variant="bodyS">
            <FormattedMessageWrapper
              id="errors.incorrectCCDescription"
              defaultMessage="Reenter your card number and try again"
            />
          </Text>
        </ErrorBanner>
      </div>
    </ContentWrapper>
  )
})

WrongCCNumber.displayName = 'WrongCCNumber'
