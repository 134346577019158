import {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toggle} from 'opticks'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {
  getIsAuthenticated,
  getUser
} from '@daedalus/core/src/auth/modules/selectors'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'
import {useGetBoVioConfiguration} from '@daedalus/core/src/offer/services/offerConfigurationApi/useGetBoVioConfiguration'
import {getUrlParams} from '@daedalus/core/src/utils/url'

import {
  getApiContext,
  getApplication,
  getIsDealFreezeEnabled
} from '../modules/selector'
import {setDealFreezeEnabled} from '../modules/slice'
import {isCheckInDateValid} from '../utils/OfferEligibility'

const SUPPORTED_LANGUAGES = ['en', 'en-GB']

/**
 * Custom hook to determine if the DealFreeze feature should be enabled based on the application state,
 * user data and check-in date.
 * @returns boolean indicating if DealFreeze is enabled
 */
export const useDealFreezeEnabled = (): boolean => {
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()
  const {currency, languageCode, page} = useSelector(getApplication)
  const {boVioProfile, boVioEndpoint} = useSelector(getApiContext)
  const brand = useSelector(getBrand)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const user = useSelector(getUser)
  const isDealFreezeEnabled = useSelector(getIsDealFreezeEnabled)

  const isVioBrand = useMemo(() => checkIsBrandVio(brand), [brand])

  const configuration = useGetBoVioConfiguration({
    boVioUrl: boVioEndpoint,
    boVioProfile
  })

  const dealFreezeConfiguration = useMemo(() => {
    return configuration?.dealFreeze || {}
  }, [configuration])
  const {checkIn} = getUrlParams()

  const hasUserRequiredData = useMemo(() => {
    const {given_name, family_name, email} = user?.attributes || {}
    return Boolean(given_name && family_name && email)
  }, [user])

  /**
   * If the user is not authenticated, there is no need to validate their data (name and email).
   * When they click on DF, it will open the SUSI flow instead.
   */
  const shouldUserSeeDealFreeze = !isAuthenticated || hasUserRequiredData

  const isCheckInValid = useMemo(() => {
    return (
      Boolean(checkIn) &&
      isCheckInDateValid(
        checkIn as string,
        new Date(),
        dealFreezeConfiguration.offerCheckInDayDistanceStartLimit,
        dealFreezeConfiguration.offerCheckInDayDistanceEndLimit
      )
    )
  }, [checkIn, dealFreezeConfiguration])

  const isDealFreezeAbEnabled =
    toggle('feature-deal-freeze-desktop', isMobile, true) &&
    toggle('c3f23fa5-dealfreeze-public-access', false, true)

  useEffect(() => {
    const shouldEnableDealFreeze = Boolean(
      isVioBrand &&
        currency &&
        dealFreezeConfiguration.enabled &&
        SUPPORTED_LANGUAGES.includes(languageCode || '') &&
        isCheckInValid &&
        shouldUserSeeDealFreeze &&
        page !== Page.Payment &&
        isDealFreezeAbEnabled
    )
    dispatch(setDealFreezeEnabled(shouldEnableDealFreeze))
  }, [
    isMobile,
    isVioBrand,
    currency,
    dealFreezeConfiguration.enabled,
    isCheckInValid,
    shouldUserSeeDealFreeze,
    dispatch,
    languageCode,
    page,
    isDealFreezeAbEnabled
  ])

  return isDealFreezeEnabled
}
