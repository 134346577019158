import * as React from 'react'
import {equals} from 'ramda'

import urlParamsMapper from './URLParamsMapper'

type WithURLParamsMapperStateType = {
  urlParams: Record<string, unknown>
}

function withURLParamsMapper<Config extends Record<string, unknown>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: (...props: any) => JSX.Element | null
) {
  class WithURLParamsMapper extends React.PureComponent<
    Config,
    WithURLParamsMapperStateType
  > {
    state = {
      urlParams: urlParamsMapper()
    }

    static getDerivedStateFromProps(
      props: Record<string, unknown>,
      state: WithURLParamsMapperStateType
    ) {
      if (equals(state.urlParams, urlParamsMapper())) return null

      return {
        urlParams: urlParamsMapper()
      }
    }

    render() {
      return <Component {...this.props} {...this.state.urlParams} />
    }
  }
  // prettier-ignore
  ;(WithURLParamsMapper as typeof WithURLParamsMapper & {displayName: string}).displayName = `
withURLParamsMapper(${Component.name || ''})
`
  return WithURLParamsMapper
}

export default withURLParamsMapper
