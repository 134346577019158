// TODO (Core): Add unit tests for these functions
import {brands} from '../config'
import {Brand, BrandCode} from '../types'

/**
 * Checks whether the passed-in brand has CookiePro enabled
 */
export const checkIsCookieProEnabledForBrand = (brand: Brand) =>
  Boolean(brand.cookieProId)

/**
 * Checks whether the passed-in brand is Vio
 */
export const checkIsBrandVio = (brand: Brand) => brand.code === 'vio'

/**
 * Checks whether the passed-in brand is Etrip
 */
export const checkIsBrandEtrip = (brand: Brand) => brand.code === 'etrip'

/**
 * Checks whether the passed-in brand is GoSeek
 */
export const checkIsBrandGoSeek = (brand: Brand) => brand.code === 'goseek'

/**
 * Checks whether the passed-in brand is Kiwi
 */
export const checkIsBrandKiwi = (brand: Brand) => brand.code === 'kiwi'
/**
 * Checks whether the passed-in brand is DealBase
 */
export const checkIsBrandDealbase = (brand: Brand) => brand.code === 'dealbase'

/**
 * Checks whether the brand is internal (Vio, Etrip, GoSeek)
 */
export const checkIsBrandInternal = (brand: Brand) => brand.isInternal

/**
 * Checks whether the passed-in brand is a meta-to-meta brand
 */
export const checkIsBrandOffersLockedByDefault = (brand: Brand) =>
  brand.offersLockedByDefault

/**
 * Returns the brandCode for the matching partnerKey or "vio" if no matching partnerKey is found
 */
export const getBrandCodeFromPartnerKey = (partnerKey?: string): BrandCode => {
  for (const brandCode in brands) {
    const typedBrandCode = brandCode as BrandCode

    if (brands[typedBrandCode].partnerKey === partnerKey) {
      return brands[typedBrandCode].code
    }
  }
  return brands.vio.code
}
